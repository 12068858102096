import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuthStore } from "@/stores/auth";
import Avatar from "@components/Avatar";
import useClickOutside from "@/hooks/useClickOutside";

const Navbar = () => {
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const profileRef = useRef(null);
  const notificationsRef = useRef(null);
  const messagesRef = useRef(null);

  const { user, logout } = useAuthStore();

  const toggleDropdown = (id: string) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  useClickOutside(profileRef, () => {
    if (activeDropdown === "profile") setActiveDropdown(null);
  });

  useClickOutside(notificationsRef, () => {
    if (activeDropdown === "notifications") setActiveDropdown(null);
  });

  useClickOutside(messagesRef, () => {
    if (activeDropdown === "messages") setActiveDropdown(null);
  });

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  return (
    <div className="iq-top-navbar">
      <div className="iq-navbar-custom d-flex align-items-center justify-content-between w-100">
        <div className="iq-navbar-logo d-flex align-items-center justify-content-between">
          <div className="iq-menu-bt align-self-center">
            <div
              className="wrapper-menu"
              onClick={() => document.body.classList.toggle("sidebar-main")}
            >
              <div className="main-circle">
                <i className="ri-menu-line"></i>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <div className="nav-item search-box px-3">
            <form action="#" className="search-form">
              <input
                type="text"
                className="text search-input"
                placeholder="Buscar..."
              />
              <a href="#!" className="search-link">
                <i className="ri-search-2-line"></i>
              </a>
            </form>
          </div>

          <ul className="navbar-list d-flex align-items-center mb-0">
            {/* Messages */}
            <li ref={messagesRef} className="nav-item px-2">
              <a
                href="#!"
                className={`iq-waves-effect ${
                  activeDropdown === "messages" ? "active" : ""
                }`}
                onClick={() => toggleDropdown("messages")}
              >
                <i className="ri-mail-line"></i>
                <span className="badge badge-primary count-mail">3</span>
              </a>
              {activeDropdown === "messages" && (
                <div className="iq-sub-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body">
                      <div className="bg-primary p-3">
                        <h5 className="mb-0 text-white">
                          Mensajes
                          <small className="badge badge-light float-right pt-1">
                            5
                          </small>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </li>

            {/* Notifications */}
            <li ref={notificationsRef} className="nav-item px-2">
              <a
                href="#!"
                className={`iq-waves-effect ${
                  activeDropdown === "notifications" ? "active" : ""
                }`}
                onClick={() => toggleDropdown("notifications")}
              >
                <i className="ri-notification-2-line"></i>
                <span className="bg-danger dots"></span>
              </a>
            </li>

            {/* Fullscreen */}
            <li className="nav-item px-2">
              <a
                href="#!"
                className="iq-waves-effect"
                onClick={toggleFullscreen}
              >
                <i
                  className={`ri-${
                    isFullscreen ? "fullscreen-exit-line" : "fullscreen-line"
                  }`}
                ></i>
              </a>
            </li>

            {/* Profile */}
            <li
              ref={profileRef}
              className={`nav-item px-2 ${
                activeDropdown === "profile" ? "iq-show" : ""
              }`}
            >
              <Avatar
                name={user?.email || ""}
                size="md"
                showStatus
                status="online"
                onClick={() => toggleDropdown("profile")}
                className={activeDropdown === "profile" ? "active" : ""}
              />
              {activeDropdown === "profile" && (
                <div className="iq-sub-dropdown iq-user-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0">
                      <div className="bg-primary p-3">
                        <h5 className="mb-0 text-white">Hola, {user?.email}</h5>
                        <span className="text-white font-size-12">
                          Disponible
                        </span>
                      </div>
                      <Link
                        to="/profile"
                        className="iq-sub-card iq-bg-primary-hover"
                      >
                        <div className="media align-items-center">
                          <div className="rounded iq-card-icon iq-bg-primary">
                            <i className="ri-file-user-line"></i>
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0">Mi Perfil</h6>
                            <p className="mb-0 font-size-12">
                              Ver detalles de perfil.
                            </p>
                          </div>
                        </div>
                      </Link>
                      <div className="d-inline-block w-100 text-center p-3">
                        <a
                          href="#!"
                          className="iq-bg-danger iq-sign-btn"
                          onClick={logout}
                        >
                          Cerrar Sesión
                          <i className="ri-login-box-line ml-2"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
