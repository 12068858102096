// src/routes/configs/auth.routes.ts
import { RouteConfig } from "../types";
import * as LazyComponents from "../lazyComponents";

export const authRoutes: RouteConfig[] = [
  {
    path: "login",
    component: LazyComponents.Login,
    isPublic: true,
  },
  {
    path: "register",
    component: LazyComponents.Register,
    isPublic: true,
  },
  {
    path: "verify-email",
    component: LazyComponents.VerifyEmail,
    isPublic: true,
  },
  {
    path: "token-verification-email",
    component: LazyComponents.TokenVerificationEmail,
    isPublic: true,
  },
  {
    path: "logout",
    component: LazyComponents.Logout,
  },
];
