import { MenuItem } from "./types";

export const menuItems: MenuItem[] = [
  {
    label: "Main",
    isTitle: true,
    icon: "ri-separator",
  },
  {
    label: "App",
    icon: "ri-home-4-line",
    subMenu: [{ label: "Home", path: "/dashboard/home" }],
  },

  // ... Puedes continuar agregando los demás elementos del menú
];
