//src/components/RouteWrapper/index.tsx
import React, { Suspense } from "react";
import Spinner from "@components/Spinner";
import PublicRoute from "@components/PublicRoute";
import PrivateRoute from "@components/PrivateRoute";

// Interface para definir las props que acepta el componente
interface RouteWrapperProps {
  component: React.LazyExoticComponent<React.FC>;
  isPublic?: boolean;
  isPrivate?: boolean;
}

export const RouteWrapper: React.FC<RouteWrapperProps> = ({
  component: Component,
  isPublic,
  isPrivate,
}) => {
  const wrapped = (
    <Suspense fallback={<Spinner />}>
      <Component />
    </Suspense>
  );

  if (isPublic) {
    return <PublicRoute element={wrapped} />;
  }

  if (isPrivate) {
    return <PrivateRoute element={wrapped} />;
  }

  return wrapped;
};
