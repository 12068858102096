// src/components/DashboardLayout/index.tsx
import React from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className={`wrapper`}>
      <Sidebar />
      <Navbar />
      <div className="main-content">
        <div id="content-page" className="content-page">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
