import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MenuItem } from "./types";
import { menuItems } from "./menuConfig";

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [sidebarActive, setSidebarActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    menuItems.forEach((item) => {
      if (item.subMenu?.some((sub) => sub.path === location.pathname)) {
        setActiveMenu(item.label);
      }
    });
  }, [location.pathname]);

  const toggleSubmenu = (label: string, e: React.MouseEvent) => {
    e.preventDefault();
    setActiveMenu(activeMenu === label ? null : label);
  };

  const isMenuActive = (item: MenuItem): boolean => {
    if (item.path === location.pathname) return true;
    return item.subMenu?.some((sub) => sub.path === location.pathname) || false;
  };

  const renderMenuItem = (item: MenuItem) => {
    if (item.isTitle) {
      return (
        <li key={item.label} className="iq-menu-title">
          <i className="ri-separator"></i>
          <span>{item.label}</span>
        </li>
      );
    }

    const isActive = isMenuActive(item);
    const hasSubmenu = item.subMenu && item.subMenu.length > 0;
    const isOpen = activeMenu === item.label;

    return (
      <li key={item.label} className={isActive ? "active" : ""}>
        {hasSubmenu ? (
          <>
            <a
              href="#"
              className={`iq-waves-effect collapsed ${
                isActive || isOpen ? "active" : ""
              }`}
              data-toggle="collapse"
              aria-expanded={isOpen ? "true" : "false"}
              onClick={(e) => toggleSubmenu(item.label, e)}
            >
              <i className={item.icon}></i>
              <span>{item.label}</span>
              <i className="ri-arrow-right-s-line iq-arrow-right"></i>
            </a>
            <ul
              id={item.label.toLowerCase().replace(/\s+/g, "-")}
              className={`iq-submenu collapse ${isOpen ? "show" : ""}`}
              data-parent="#iq-sidebar-toggle"
            >
              {item?.subMenu?.map((subItem) => (
                <li
                  key={subItem.label}
                  className={location.pathname === subItem.path ? "active" : ""}
                >
                  <NavLink to={subItem.path || "#"}>{subItem.label}</NavLink>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <NavLink to={item.path || "#"} className="iq-waves-effect">
            <i className={item.icon}></i>
            <span>{item.label}</span>
          </NavLink>
        )}
      </li>
    );
  };

  return (
    <div className="iq-sidebar">
      <div className="iq-sidebar-logo d-flex justify-content-between">
        <NavLink to="/" className="header-logo">
          <img
            src="/src/assets/images/logo.png"
            className="img-fluid"
            alt="logo"
          />
          <span>Sofbox</span>
        </NavLink>
        <div className="iq-menu-bt align-self-center">
          <div
            className={`wrapper-menu ${sidebarActive ? "open" : ""}`}
            onClick={() => {
              document.body.classList.toggle("sidebar-main");
              setSidebarActive(!sidebarActive);
            }}
          >
            <div className="line-menu half start"></div>
            <div className="line-menu"></div>
            <div className="line-menu half end"></div>
          </div>
        </div>
      </div>
      <div id="sidebar-scrollbar">
        <nav className="iq-sidebar-menu">
          <ul id="iq-sidebar-toggle" className="iq-menu">
            {menuItems.map(renderMenuItem)}
          </ul>
        </nav>
        <div className="p-3"></div>
      </div>
    </div>
  );
};

export default Sidebar;
