// src/components/Avatar/index.tsx
import React from "react";

interface AvatarProps {
  src?: string;
  name: string;
  size?: "sm" | "md" | "lg";
  className?: string;
  showStatus?: boolean;
  status?: "online" | "offline" | "away" | "busy";
  onClick?: () => void;
}

const Avatar: React.FC<AvatarProps> = ({
  src,
  name,
  size = "md",
  className = "",
  showStatus = false,
  status = "offline",
  onClick,
}) => {
  const getInitials = (name: string) => {
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase()
      .slice(0, 2);
  };

  const sizeClasses = {
    sm: "35px",
    md: "40px",
    lg: "45px",
  };

  const fontSize = {
    sm: "14px",
    md: "16px",
    lg: "18px",
  };

  const containerStyle: React.CSSProperties = {
    width: sizeClasses[size],
    height: sizeClasses[size],
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: fontSize[size],
    backgroundColor: "#e5f2ff",
    color: "#0084ff",
    cursor: onClick ? "pointer" : "default",
    position: "relative",
    overflow: "hidden",
    border: "1px solid rgba(0, 0, 0, 0.1)",
  };

  const statusStyle: React.CSSProperties = showStatus
    ? {
        position: "absolute",
        bottom: "2px",
        right: "2px",
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        border: "2px solid #fff",
        backgroundColor:
          status === "online"
            ? "#00ca00"
            : status === "away"
            ? "#ffd400"
            : status === "busy"
            ? "#e64141"
            : "#777D74",
      }
    : {};

  return (
    <div style={containerStyle} className={className} onClick={onClick}>
      {src ? (
        <img
          src={src}
          alt={name}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        <span style={{ fontWeight: 500 }}>{getInitials(name)}</span>
      )}
      {showStatus && <span style={statusStyle} />}
    </div>
  );
};

export default Avatar;
