// src/views/layouts/DashboardLayout.tsx
import React from "react";
import { Outlet } from "react-router-dom";
import Layout from "@/components/DashboardLayout";

const Dashboard: React.FC = () => {
  return (
    <Layout>
      <Outlet /> {/* Aquí se renderizan las páginas del dashboard */}
    </Layout>
  );
};

export default Dashboard;
