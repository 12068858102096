// src/routes/lazyComponents.ts
import { lazy } from "react";

// Auth Components
export const Login = lazy(() => import("@views/Auth/Login"));
export const Register = lazy(() => import("@views/Auth/Register"));
export const VerifyEmail = lazy(() => import("@views/Auth/VerifyEmail"));
export const Logout = lazy(() => import("@views/Auth/Logout"));
export const TokenVerificationEmail = lazy(
  () => import("@views/Auth/TokenVerificationEmail")
);

// Dashboard Components
export const Home = lazy(() => import("@views/Dashboard/Home"));
