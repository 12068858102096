import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PageTransition from "@components/PageTransition";

import { RouteWrapper } from "@components/RouteWrapper";
import { authRoutes } from "./configs/auth.routes";
import { dashboardRoutes } from "./configs/dashboard.routes";
import PrivateRoute from "@components/PrivateRoute";
import DashboardLayout from "@views/layouts/DashboardLayout";

const AppRoutes: React.FC = () => {
  return (
    <PageTransition>
      <Routes>
        {/* Rutas de autenticación */}
        {authRoutes.map(({ path, component, isPublic }) => (
          <Route
            key={path}
            path={path}
            element={<RouteWrapper component={component} isPublic={isPublic} />}
          />
        ))}

        {/* Rutas del dashboard con layout compartido */}
        <Route
          path="/dashboard"
          element={<PrivateRoute element={<DashboardLayout />} />}
        >
          <Route index element={<Navigate to="home" replace />} />
          {dashboardRoutes.map(({ path, component }) => (
            <Route
              key={path}
              path={path}
              element={<RouteWrapper component={component} />}
            />
          ))}
        </Route>

        {/* Ruta por defecto */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </PageTransition>
  );
};

export default AppRoutes;
