// src/hooks/useClickOutside.ts
import { useEffect, RefObject } from "react";

const useClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: () => void,
  exceptRef?: RefObject<HTMLElement>
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const target = event.target as Node;

      // Si el click fue dentro del elemento exceptuado, no hacemos nada
      if (exceptRef?.current?.contains(target)) {
        return;
      }

      // Si el click fue fuera del elemento referenciado, ejecutamos el handler
      if (!ref.current || !ref.current.contains(target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, exceptRef]);
};

export default useClickOutside;
